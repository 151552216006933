<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Basic Information
                <br>
                <small>All information requested from this Basic Information Form, will populate to the following state forms.</small>
              </b-card-title>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form>

                  <b-form-input
                    v-model="form.form"
                    type="text"
                    class="hidden"
                  />

                  <b-form-input
                    v-model="form.id"
                    type="text"
                    class="hidden"
                  />

                  <b-row>

                    <!-- Input Last Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Last Name (Family Name)"
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Last Name (Family Name)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          vid="last-name"
                          rules="required"
                        >
                          <b-form-input
                            id="last-name"
                            ref="lastName"
                            v-model="form.lastName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Last Name"
                            title="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input First Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* First Name (Given Name)"
                        label-for="first-name"
                      >
                        <label
                          class="sr-only"
                          for="first-name"
                        >
                          * First Name (Given Name)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          vid="first-name"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name"
                            ref="firstName"
                            v-model="form.firstName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="First Name"
                            title="First Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Middle Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Middle Name"
                        label-for="middle-name"
                      >
                        <label
                          class="sr-only"
                          for="middle-name"
                        >
                          Middle Name
                        </label>
                        <b-form-input
                          id="middle-name"
                          ref="middleName"
                          v-model="form.middleName"
                          type="text"
                          placeholder="Middle Name"
                          title="Middle Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input AKA Last Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="AKA Last Name"
                        label-for="aka-last-name"
                      >
                        <label
                          class="sr-only"
                          for="aka-last-name"
                        >
                          AKA Last Name
                        </label>
                        <b-form-input
                          id="aka-last-name"
                          ref="akaLastName"
                          v-model="form.akaLastName"
                          type="text"
                          placeholder="AKA Last Name"
                          title="AKA Last Name"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input AKA First Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="AKA First Name"
                        label-for="aka-first-name"
                      >
                        <label
                          class="sr-only"
                          for="aka-first-name"
                        >
                          AKA First Name
                        </label>
                        <b-form-input
                          id="aka-first-name"
                          ref="akaFirstName"
                          v-model="form.akaFirstName"
                          type="text"
                          placeholder="AKA First Name"
                          title="AKA First Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <b-row>

                    <!-- Input Date of Birth -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Birth"
                        vid="date-of-birth"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Birth"
                          label-for="date-of-birth"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-of-birth"
                          >
                            * Date of Birth
                          </label>
                          <flat-pickr
                            id="date-of-birth"
                            ref="dateOfBirth"
                            v-model="form.dateOfBirth"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date of Birth"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Place of Birth -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Place of Birth"
                        label-for="place-of-birth"
                      >
                        <label
                          class="sr-only"
                          for="place-of-birth"
                        >
                          * Place of Birth
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Place of Birth"
                          vid="place-of-birth"
                          rules="required"
                        >
                          <b-form-input
                            id="place-of-birth"
                            ref="placeOfBirth"
                            v-model="form.placeOfBirth"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Place of Birth"
                            title="Place of Birth"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Sex -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Sex"
                        rules="required"
                      >
                        <b-form-group
                          label="* Sex"
                          label-for="sex"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="sex"
                          >
                            * Sex
                          </label>
                          <v-select
                            ref="sex"
                            v-model="form.sex"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sexOptions"
                            placeholder="Select Sex"
                            title="Sex"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Marital Status -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Marital Status"
                        rules="required"
                      >
                        <b-form-group
                          label="* Marital Status"
                          label-for="marital-status"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="marital-status"
                          >
                            * Marital Status
                          </label>
                          <v-select
                            ref="maritalStatus"
                            v-model="form.maritalStatus"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="maritalStatusOptions"
                            placeholder="Select Marital Status"
                            title="Marital Status"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Weight -->
                    <b-col
                      cols="6"
                      lg="2"
                    >

                      <b-form-group
                        label="* Weight (lb)"
                        label-for="weight"
                      >
                        <label
                          class="sr-only"
                          for="weight"
                        >
                          * Weight (lb)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Weight"
                          vid="weight"
                          rules="required"
                        >
                          <b-form-input
                            id="weight"
                            ref="weight"
                            v-model="form.weight"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            step="0.1"
                            placeholder="Weight"
                            title="Weight"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Height -->
                    <b-col
                      cols="6"
                      lg="2"
                    >

                      <b-form-group
                        label="* Height (cm)"
                        label-for="weight"
                      >
                        <label
                          class="sr-only"
                          for="weight"
                        >
                          * Height (cm)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Height"
                          vid="height"
                          rules="required"
                        >
                          <b-form-input
                            id="height"
                            ref="height"
                            v-model="form.height"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            step="0.1"
                            placeholder="Height"
                            title="Height"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Eye Color -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Eye Color"
                        rules="required"
                      >
                        <b-form-group
                          label="* Eye Color"
                          label-for="eye-color"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="eye-color"
                          >
                            * Eye Color
                          </label>
                          <v-select
                            ref="eyeColor"
                            v-model="form.eyeColor"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="eyeColorOptions"
                            multiple
                            taggable
                            push-tags
                            placeholder="Select or Type Eye Color"
                            title="Eye Color"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Hair Color -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Hair Color"
                        rules="required"
                      >
                        <b-form-group
                          label="* Hair Color"
                          label-for="hair-color"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="hair-color"
                          >
                            * Hair Color
                          </label>
                          <v-select
                            ref="hairColor"
                            v-model="form.hairColor"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="hairColorOptions"
                            multiple
                            taggable
                            push-tags
                            placeholder="Select or Type Hair Color"
                            title="Hair Color"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Address -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Address"
                        label-for="address"
                      >
                        <label
                          class="sr-only"
                          for="address"
                        >
                          * Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Address"
                          vid="address"
                          rules="required"
                        >
                          <b-form-input
                            id="address"
                            ref="address"
                            v-model="form.address"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Address"
                            title="Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Apt/Unit -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Apt/Unit"
                        label-for="aptunit"
                      >
                        <label
                          class="sr-only"
                          for="aptunit"
                        >
                          Apt/Unit
                        </label>
                        <b-form-input
                          id="aptunit"
                          ref="aptunit"
                          v-model="form.aptunit"
                          type="text"
                          placeholder="Apt/Unit"
                          title="Apt/Unit"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input Street Number and/or Name -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Street Number and/or Name"
                        label-for="street"
                      >
                        <label
                          class="sr-only"
                          for="street"
                        >
                          * Street Number and/or Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Street Number and/or Name"
                          vid="street"
                          rules="required"
                        >
                          <b-form-input
                            id="street"
                            ref="street"
                            v-model="form.street"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Street Number and/or Name"
                            title="Street Number and/or Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Street or POB -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Street or POB"
                        label-for="street-pob"
                      >
                        <label
                          class="sr-only"
                          for="street-pob"
                        >
                          Street or POB
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Street or POB"
                          vid="street-pob"
                          rules="required"
                        >
                          <b-form-input
                            id="street-pob"
                            ref="streetPob"
                            v-model="form.streetPob"
                            type="text"
                            placeholder="Street or POB"
                            title="Street or POB"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input City or Town -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* City or Town"
                        label-for="city-town"
                      >
                        <label
                          class="sr-only"
                          for="city-town"
                        >
                          * City or Town
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City or Town"
                          vid="city-town"
                          rules="required"
                        >
                          <b-form-input
                            id="city-town"
                            ref="cityTown"
                            v-model="form.cityTown"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="City or Town"
                            title="City or Town"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input State -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <!-- <b-form-group
                        label="* State"
                        label-for="state"
                      >
                        <label
                          class="sr-only"
                          for="state"
                        >
                          * State
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          vid="state"
                          rules="required"
                        >
                          <b-form-input
                            id="state"
                            ref="state"
                            v-model="form.state"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="State"
                            title="State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group> -->

                      <validation-provider
                        #default="{ errors }"
                        name="State"
                        rules="required"
                      >
                        <b-form-group
                          label="* State"
                          label-for="state"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="state"
                          >
                            * State
                          </label>
                          <v-select
                            ref="state"
                            v-model="form.state"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="stateOptions"
                            taggable
                            push-tags
                            placeholder="Select or Type State"
                            title="State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Zip Code -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Zip Code"
                        label-for="zip-code"
                      >
                        <label
                          class="sr-only"
                          for="zip-code"
                        >
                          * Zip Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Zip Code"
                          vid="zip-code"
                          rules="required|regex:^[\-0-9]+$"
                        >
                          <b-form-input
                            id="zip-code"
                            ref="zipCode"
                            v-model="form.zipCode"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Zip Code"
                            title="Zip Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Email Address -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Email Address"
                        label-for="email-address"
                      >
                        <label
                          class="sr-only"
                          for="email-address"
                        >
                          Email Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Email Address"
                          vid="email-address"
                          :mode="custom"
                          rules="required|email|not-exist"
                        >
                          <b-form-input
                            id="email-address"
                            ref="emailAddress"
                            v-model="form.emailAddress"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            placeholder="Email Address"
                            title="Email Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Phone Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Phone Number"
                        label-for="phone-number"
                      >
                        <label
                          class="sr-only"
                          for="phone-number"
                        >
                          * Phone Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Phone Number"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="phone-number"
                              ref="phoneNumber"
                              v-model="form.phoneNumber"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="Phone Number"
                              title="Phone Number"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Mobile Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Mobile Number"
                        label-for="mobile-number"
                      >
                        <label
                          class="sr-only"
                          for="mobile-number"
                        >
                          Mobile Number
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="mobile-number"
                            ref="mobileNumber"
                            v-model="form.mobileNumber"
                            v-mask="['###-###-####']"
                            type="tel"
                            placeholder="Mobile Number"
                            title="Mobile Number"
                          />
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input Fax Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Fax Number"
                        label-for="fax-number"
                      >
                        <label
                          class="sr-only"
                          for="fax-number"
                        >
                          Fax Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Fax Number"
                          vid="fax-number"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="fax-number"
                              ref="faxNumber"
                              v-model="form.faxNumber"
                              v-mask="['### ### ####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="Fax Number"
                              title="Fax Number"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Emergency Contact Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Emergency Contact Name"
                        label-for="emergency-contact-name"
                      >
                        <label
                          class="sr-only"
                          for="emergency-contact-name"
                        >
                          * Emergency Contact Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Emergency Contact Name"
                          vid="emergency-contact-name"
                          rules="required"
                        >
                          <b-form-input
                            id="emergency-contact-name"
                            ref="emergencyContactName"
                            v-model="form.emergencyContactName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Emergency Contact Name"
                            title="Emergency Contact Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Emergency Contact Email Address -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Emergency Contact Email Address"
                        label-for="emergency-contact-email-address"
                      >
                        <label
                          class="sr-only"
                          for="emergency-contact-email-address"
                        >
                          Emergency Contact Email Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Emergency Contact Email Address"
                          vid="emergency-contact-email-address"
                          rules="email"
                        >
                          <b-form-input
                            id="emergency-contact-email-address"
                            ref="emergencyContactEmailAddress"
                            v-model="form.emergencyContactEmailAddress"
                            type="email"
                            placeholder="Emergency Contact Email Address"
                            title="Emergency Contact Email Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Emergency Contact Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Emergency Contact Number"
                        label-for="emergency-contact-number"
                      >
                        <label
                          class="sr-only"
                          for="emergency-contact-number"
                        >
                          * Emergency Contact Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Emergency Contact Number"
                          vid="emergency-contact-number"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="emergency-contact-number"
                              ref="emergencyContactNumber"
                              v-model="form.emergencyContactNumber"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="Emergency Contact Number"
                              title="Emergency Contact Number"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input SSN -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="SSN"
                        label-for="ssn"
                      >
                        <label
                          class="sr-only"
                          for="ssn"
                        >
                          SSN
                        </label>
                        <b-form-input
                          id="ssn"
                          ref="ssn"
                          v-model="form.ssn"
                          type="text"
                          placeholder="SSN"
                          title="SSN"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input TIN -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="TIN"
                        label-for="tin"
                      >
                        <label
                          class="sr-only"
                          for="tin"
                        >
                          TIN
                        </label>
                        <b-form-input
                          id="tin"
                          ref="tin"
                          v-model="form.tin"
                          type="text"
                          placeholder="TIN"
                          title="TIN"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input CDL -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="CDL"
                        label-for="cdl"
                      >
                        <label
                          class="sr-only"
                          for="cdl"
                        >
                          CDL
                        </label>
                        <b-form-input
                          id="cdl"
                          ref="cdl"
                          v-model="form.cdl"
                          type="text"
                          placeholder="CDL"
                          title="CDL"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input ID Type -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="ID Type"
                        rules="required"
                      >
                        <b-form-group
                          label="* ID Type"
                          label-for="id-type"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="id-type"
                          >
                            * ID Type
                          </label>
                          <v-select
                            ref="idType"
                            v-model="form.idType"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="idTypeOptions"
                            taggable
                            push-tags
                            placeholder="Select or Type ID Type"
                            title="ID Type"
                            @input="form.idType !== 'Driver\'s License' ? form.idPreferences = false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Radio Insured Automobile -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Insured Automobile"
                        label-for="insured-automobile"
                      >
                        <label
                          class="sr-only"
                          for="insured-automobile"
                        >
                          Insured Automobile
                        </label>
                        <b-form-radio
                          ref="idPreferences"
                          v-model="form.idPreferences"
                          inline
                          value="Insured Automobile"
                          :disabled="form.idType !== 'Driver\'s License'"
                          title="Insured Automobile"
                        >
                          Yes
                        </b-form-radio>
                        <b-form-radio
                          ref="idPreferences"
                          v-model="form.idPreferences"
                          inline
                          value="No"
                          :disabled="form.idType !== 'Driver\'s License'"
                          title="Insured Automobile"
                        >
                          No
                        </b-form-radio>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input ID State Issued -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* ID State Issued"
                        label-for="id-state-issued"
                      >
                        <label
                          class="sr-only"
                          for="id-state-issued"
                        >
                          * ID State Issued
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="ID State Issued"
                          vid="id-state-issued"
                          rules="required"
                        >
                          <b-form-input
                            id="id-state-issued"
                            ref="idStateIssued"
                            v-model="form.idStateIssued"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="ID State Issued"
                            title="ID State Issued"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input ID Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* ID Number"
                        label-for="id-number"
                      >
                        <label
                          class="sr-only"
                          for="id-number"
                        >
                          * ID Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="ID Number"
                          vid="id-number"
                          rules="required"
                        >
                          <b-form-input
                            id="id-number"
                            ref="idNumber"
                            v-model="form.idNumber"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="ID Number"
                            title="ID Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input ID Expiration Date -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="ID Expiration Date"
                        vid="id-expiration-date"
                        rules="required"
                      >
                        <b-form-group
                          label="* ID Expiration Date"
                          label-for="id-expiration-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="id-expiration-date"
                          >
                            * ID Expiration Date
                          </label>
                          <flat-pickr
                            id="id-expiration-date"
                            ref="idExpirationDate"
                            v-model="form.idExpirationDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="ID Expiration Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <!-- Input ID Document -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* ID Document"
                        label-for="id-document"
                      >
                        <label
                          class="sr-only"
                          for="id-document"
                        >
                          * ID Document
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="ID Document"
                          rules="required"
                        >
                          <b-form-file
                            ref="idDocument"
                            v-model="form.idDocument"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            type="file"
                            accept="image/*"
                            multiple
                            capture
                            title="ID Document"
                          >
                            <template
                              slot="file-name"
                              slot-scope="{ names }"
                            >
                              <b-badge variant="primary">
                                {{ names[0] }}
                              </b-badge>
                              <b-badge
                                v-if="names.length > 1"
                                variant="dark"
                                class="ml-1"
                              >
                                + {{ names.length - 1 }} More files
                              </b-badge>
                            </template>
                          </b-form-file>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-list-group v-if="idDocumentFile">

                        <b-list-group-item
                          v-for="(item, index) in idDocumentFile"
                          :key="item"
                          class="d-flex justify-content-between text-truncate"
                        >

                          <span class="d-inline-block text-truncate mr-1">
                            <b-link
                              :href="`${urlUpload}${id}/${item}`"
                              target="_blank"
                              class="font-weight-bold pointer-events-auto"
                            >
                              {{ item }}
                            </b-link>
                          </span>

                          <span class="d-inline-block">
                            <feather-icon
                              :id="`row-${index}-id-document-preview-icon`"
                              icon="EyeIcon"
                              size="16"
                              class="mr-1 pointer-events-auto"
                              role="button"
                              @click="previewFormFile({ id: id, item: item })"
                            />
                            <b-tooltip
                              title="Preview"
                              :target="`row-${index}-id-document-preview-icon`"
                            />

                            <feather-icon
                              :id="`row-${index}-id-document-delete-icon`"
                              icon="TrashIcon"
                              size="16"
                              role="button"
                              @click="deleteFormFile({ id: id, idDocument: item })"
                            />
                            <b-tooltip
                              title="Delete"
                              :target="`row-${index}-id-document-delete-icon`"
                            />
                          </span>
                        </b-list-group-item>
                      </b-list-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Misc. Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Misc. Number"
                        label-for="misc-number"
                      >
                        <label
                          class="sr-only"
                          for="misc-number"
                        >
                          Misc. Number
                        </label>
                        <b-form-input
                          id="misc-number"
                          ref="miscNumber"
                          v-model="form.miscNumber"
                          type="text"
                          placeholder="Misc. Number"
                          title="Misc. Number"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input Misc. Number Bil -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Misc. Number Bil"
                        label-for="misc-number-bil"
                      >
                        <label
                          class="sr-only"
                          for="misc-number-bil"
                        >
                          Misc. Number Bil
                        </label>
                        <b-form-input
                          id="misc-number-bil"
                          ref="miscNumberBil"
                          v-model="form.miscNumberBil"
                          type="text"
                          placeholder="Misc. Number Bil"
                          title="Misc. Number Bil"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <b-row>

                    <!-- Input Position -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Position"
                        label-for="position"
                      >
                        <label
                          class="sr-only"
                          for="position"
                        >
                          * Position
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Position"
                          vid="position"
                          rules="required"
                        >
                          <b-form-input
                            id="position"
                            ref="position"
                            v-model="form.position"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Position"
                            title="Position"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Hire Date -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Hire Date"
                        vid="hire-date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Hire Date"
                          label-for="hire-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="hire-date"
                          >
                            * Hire Date
                          </label>
                          <flat-pickr
                            id="hire-date"
                            ref="hireDate"
                            v-model="form.hireDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Hire Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input First Day Date -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="First Day Date"
                        vid="first-day-date"
                        rules="required"
                      >
                        <b-form-group
                          label="* First Day Date"
                          label-for="first-day-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="first-day-date"
                          >
                            * First Day Date
                          </label>
                          <flat-pickr
                            id="first-day-date"
                            ref="firstDayDate"
                            v-model="form.firstDayDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="First Day Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Input Signature -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Signature"
                        label-for="signature"
                      >
                        <label
                          class="sr-only"
                          for="signature"
                        >
                          * Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="signature"
                            v-model="form.signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.signature === '' }"
                            title="Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.signature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr v-if="userData.role !== 'agent'">

                  <b-row
                    v-if="userData.role !== 'agent'"
                  >

                    <!-- Input Employment Status -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Employment Status"
                        rules="required"
                      >
                        <b-form-group
                          label="* Employment Status"
                          label-for="employment-status"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="employment-status"
                          >
                            * Employment Status
                          </label>
                          <v-select
                            ref="employmentStatus"
                            v-model="form.employmentStatus"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="employmentStatusOptions"
                            placeholder="Select Employment Status"
                            title="Employment Status"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Employment Status Date -->
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Employment Status Date"
                        vid="employment-status-date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Employment Status Date"
                          label-for="employment-status-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="employment-status-date"
                          >
                            * Employment Status Date
                          </label>
                          <flat-pickr
                            id="employment-status-date"
                            ref="employmentStatusDate"
                            v-model="form.employmentStatusDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Employment Status Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <!-- Input Profile Picture -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Profile Picture"
                        label-for="profile-picture"
                      >
                        <label
                          class="sr-only"
                          for="profile-picture"
                        >
                          * Profile Picture
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Profile Picture"
                          rules="required"
                        >
                          <b-form-file
                            ref="profilePicture"
                            v-model="form.profilePicture"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            type="file"
                            accept="image/jpeg"
                            capture
                            title="Profile Picture"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-list-group v-if="profilePictureFile">

                        <b-list-group-item
                          class="d-flex justify-content-between text-truncate"
                        >

                          <span class="d-inline-block text-truncate mr-1">
                            <b-link
                              :href="`${urlUpload}${id}/${profilePictureFile}`"
                              target="_blank"
                              class="font-weight-bold pointer-events-auto"
                            >
                              {{ profilePictureFile }}
                            </b-link>
                          </span>

                          <span class="d-inline-block">
                            <feather-icon
                              :id="`row-profile-picture-preview-icon`"
                              icon="EyeIcon"
                              size="16"
                              class="mr-1 pointer-events-auto"
                              role="button"
                              @click="previewFormFile({ id: id, item: profilePictureFile })"
                            />
                            <b-tooltip
                              title="Preview"
                              :target="`row-profile-picture-preview-icon`"
                            />

                            <feather-icon
                              :id="`row-profile-picture-delete-icon`"
                              icon="TrashIcon"
                              size="16"
                              role="button"
                              @click="deleteFormFile({ id: id, profilePicture: profilePictureFile })"
                            />
                            <b-tooltip
                              title="Delete"
                              :target="`row-profile-picture-delete-icon`"
                            />
                          </span>
                        </b-list-group-item>
                      </b-list-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <b-row>

                    <!-- Input COVID-19 Vaccine -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="COVID-19 Vaccine"
                        label-for="covid19-vaccine"
                      >
                        <label
                          class="sr-only"
                          for="covid19-vaccine"
                        >
                          COVID-19 Vaccine
                        </label>
                        <b-form-input
                          id="covid19-vaccine"
                          ref="covid19Vaccine"
                          v-model="form.covid19Vaccine"
                          type="text"
                          placeholder="COVID-19 Vaccine"
                          title="COVID-19 Vaccine"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input COVID-19 Vaccine Card -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="COVID-19 Vaccine Card"
                        label-for="covid19-vaccine-card"
                      >
                        <label
                          class="sr-only"
                          for="covid19-vaccine-card"
                        >
                          COVID-19 Vaccine Card
                        </label>
                        <b-form-file
                          ref="covid19VaccineCard"
                          v-model="form.covid19VaccineCard"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          type="file"
                          accept="image/*"
                          multiple
                          capture
                          title="COVID-19 Vaccine Card"
                        >
                          <template
                            slot="file-name"
                            slot-scope="{ names }"
                          >
                            <b-badge variant="primary">
                              {{ names[0] }}
                            </b-badge>
                            <b-badge
                              v-if="names.length > 1"
                              variant="dark"
                              class="ml-1"
                            >
                              + {{ names.length - 1 }} More files
                            </b-badge>
                          </template>
                        </b-form-file>
                      </b-form-group>

                      <b-list-group v-if="covid19VaccineCardFile">

                        <b-list-group-item
                          v-for="(item, index) in covid19VaccineCardFile"
                          :key="item"
                          class="d-flex justify-content-between text-truncate"
                        >

                          <span class="d-inline-block text-truncate mr-1">
                            <b-link
                              :href="`${urlUpload}${id}/${item}`"
                              target="_blank"
                              class="font-weight-bold pointer-events-auto"
                            >
                              {{ item }}
                            </b-link>
                          </span>

                          <span class="d-inline-block">
                            <feather-icon
                              :id="`row-${index}-covid19-vaccine-card-preview-icon`"
                              icon="EyeIcon"
                              size="16"
                              class="mr-1 pointer-events-auto"
                              role="button"
                              @click="previewFormFile({ id: id, item: item })"
                            />
                            <b-tooltip
                              title="Preview"
                              :target="`row-${index}-covid19-vaccine-card-preview-icon`"
                            />

                            <feather-icon
                              :id="`row-${index}-covid19-vaccine-card-delete-icon`"
                              icon="TrashIcon"
                              size="16"
                              role="button"
                              @click="deleteFormFile({ id: id, covid19VaccineCard: item })"
                            />
                            <b-tooltip
                              title="Delete"
                              :target="`row-${index}-covid19-vaccine-card-delete-icon`"
                            />
                          </span>
                        </b-list-group-item>
                      </b-list-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <!-- Radio Ok with Client Smoking -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="Ok with Client Smoking"
                        label-for="ok-with-client-smoking"
                      >
                        <label
                          class="sr-only"
                          for="ok-with-client-smoking"
                        >
                          Ok with Client Smoking
                        </label>
                        <b-form-radio
                          ref="caregiverPreferences"
                          v-model="form.caregiverPreferences"
                          inline
                          value="Ok with Client Smoking"
                          title="Ok with Client Smoking"
                        >
                          Yes
                        </b-form-radio>
                        <b-form-radio
                          ref="caregiverPreferences"
                          v-model="form.caregiverPreferences"
                          inline
                          value="No"
                          title="Ok with Client Smoking"
                        >
                          No
                        </b-form-radio>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <progress
                    v-if="uploadPercentage"
                    max="100"
                    :value.prop="uploadPercentage"
                    class="w-100 d-none"
                  />

                  <!-- Button Submit -->
                  <b-button
                    variant="secondary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>
        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mb-75"
          block
        >
          Print Preview
        </b-button> -->

        <!-- Button Save -->
        <b-button
          v-if="action !== 'preview'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :disabled="true"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-if="action !== 'preview'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form1', params: { action: action }, query: { id: id } }"
            :disabled="!id"
          >
            Next
          </b-button>
        </div>

        <!-- Button Back -->
        <b-button
          v-if="action === 'preview'"
          variant="primary"
          :to="{ name: 'hr' }"
          block
        >
          Back
        </b-button>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormFile, BFormRadio, BBadge, BListGroup, BListGroupItem, BLink, BTooltip, BButton, VBToggle,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { mapState } from 'vuex'
// import { mapGetters } from 'vuex'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormFile,
    BFormRadio,
    BBadge,
    BListGroup,
    BListGroupItem,
    BLink,
    BTooltip,
    BButton,

    vSelect,
    flatPickr,
    VueSignaturePad,
    // eslint-disable-next-line
    Swal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const signature = ref(null)

    const idDocumentFile = ref([])
    const profilePictureFile = ref(null)
    const covid19VaccineCardFile = ref([])

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.params.action === 'edit' || router.currentRoute.params.action === 'preview' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          // console.log(jsonObjectLiterals.data)

          if (jsonObjectLiterals.data.id) {
            form.value = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.signature) {
            signature.value.fromDataURL(jsonObjectLiterals.data.signature)
          }

          idDocumentFile.value = jsonObjectLiterals.data.idDocument
          profilePictureFile.value = jsonObjectLiterals.data.profilePicture
          covid19VaccineCardFile.value = jsonObjectLiterals.data.covid19VaccineCard

          if (form.value.id) {
            form.value.form = 'caregiver_basic_information'

            formCopy.value = JSON.parse(JSON.stringify(form.value))

            store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.status) {
                  formUpdate.value = jsonObjectLiterals.data

                  formUpdateStatus.value = formUpdate.value.status
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const sexOptions = [
      'Male',
      'Female',
    ]

    const maritalStatusOptions = [
      'Single',
      'Married',
      'Widowed',
      'Divorced',
      'Separated',
      'Registered Partnership',
    ]

    const eyeColorOptions = [
      'Black',
      'Brown',
      'Blue',
      'Hazel',
      'Amber',
      'Gray',
      'Green',
    ]

    const hairColorOptions = [
      'Black',
      'Brown',
      'Blonde',
      'Auburn',
      'Red',
      'Chestnut',
      'Gray',
      'White',
    ]

    const stateOptions = [
      'AK',
      'AL',
      'AR',
      'AS',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'GU',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OK',
      'OR',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VI',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ]

    const idTypeOptions = [
      'ID',
      'Driver\'s License',
    ]

    const employmentStatusOptions = [
      'Active',
      'Applicant',
      'Inactive',
      'New',
      'Pending',
      'Resigned',
      'Terminated',
    ]

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      // wrap: true, // set wrap to true only when using 'input-group'
      // altFormat: 'm/d/Y',
      // altInput: true,
      dateFormat: 'Y-m-d',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      signature,

      idDocumentFile,
      profilePictureFile,
      covid19VaccineCardFile,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      sexOptions,
      maritalStatusOptions,
      eyeColorOptions,
      hairColorOptions,
      stateOptions,
      idTypeOptions,
      employmentStatusOptions,

      formsOptions,

      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      mainUrl: this.$mainUrl,
      urlUpload: this.$urlUpload,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
      email,

      emailAddressExist: false,
    }
  },
  computed: {
    ...mapState('app-hr', ['uploadPercentage']),
    // ...mapGetters('app-hr', { uploadPercentage: 'getUploadPercentage' }),
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  beforeCreate() {
    // Address of the current window
    /* const address = window.location.search

    // Returns a URLSearchParams object instance
    const parameterList = new URLSearchParams(address)

    // Returning the respected value associated
    // with the provided key
    const paramId = parameterList.get('id')
    if ((paramId === undefined || paramId === '' || paramId === null)) {
      router.push({ name: 'misc-not-authorized' })
    } */

    /* eslint-disable */
    /* const url = window.location.href
    const paramString = new RegExp('(.*)[?](.*)').exec(url)
    if (null == paramString) {
      router.push({ name: 'misc-not-authorized' })
    }

    if (paramString[2].includes("&amp;")) {
      const paramList = paramString[2].split("&amp;")
    } else {
      const paramList = paramString[2].split("&")
    }

    let params = [];

    for (let i = 0; i < paramList.length; i++) {
      const values = paramList[i].split("=")
      params[values[0]] = values[1];
    } */

    /* const userInfo = getUserData()
    // return {"base": paramString[1], "params": params};
    if ((params.id === undefined || params.id === '' || params.id === null) && userInfo.role === 'admin') {
      router.push({ name: 'misc-not-authorized' })
    } */
    /* eslint-enable */
  },
  created() {
    /* if ((this.$route.query.id === undefined || this.$route.query.id === '' || this.$route.query.id === null)) {
      router.push({ name: 'misc-not-authorized' })
    } */

    const userInfo = getUserData()
    /* if (userInfo.role === 'agent') {
      this.$route.meta.resource = 'AgentAccess'
    } */
    // eslint-disable-next-line
    // console.log('here', typeof this.id, this.id, typeof userInfo.cbiId, userInfo.cbiId, (userInfo.role === 'agent' && parseInt(userInfo.cbiId) !== parseInt(this.id)))
    // eslint-disable-next-line
     if ((userInfo.role === 'agent' && parseInt(userInfo.cbiId) !== parseInt(this.id))) {
      router.push({ name: 'misc-not-authorized' })
    }

    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  mounted() {
    extend('not-exist', {
      message: 'The Email Address is already exist',
      // eslint-disable-next-line
      validate: value => new Promise(resolve => {
        const self = this

        clearTimeout(this.timeout)
        console.log(this.formCopy.emailAddress !== this.form.emailAddress)
        if (this.formCopy.emailAddress !== this.form.emailAddress) {
          this.isNotSaved = true

          this.timeout = setTimeout(() => {
            store
              .dispatch('app-hr/checkEmailAddress', { emailAddress: self.form.emailAddress })
              .then(response => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response.data.response.data

                // console.log(jsonObjectLiterals.id)

                self.isNotSaved = false

                resolve(!(jsonObjectLiterals.id !== null))
              })
              .catch(error => {
                console.log('log me here')
                console.error(error)
              })
          }, 1500)
        } else {
          resolve(true)
        }
      }),
      immediate: false,
    })
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.signature) {
            self.$refs.signature.clearSignature()
            self.$refs.signature.fromDataURL(jsonObjectLiterals.data.signature)
          }

          self.idDocumentFile = jsonObjectLiterals.data.idDocument
          self.profilePictureFile = jsonObjectLiterals.data.profilePicture
          self.covid19VaccineCardFile = jsonObjectLiterals.data.covid19VaccineCard

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    // eslint-disable-next-line
    custom(context) {
      return { on: ['input'] }
    },
    validationForm() {
      const self = this

      /* const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : '' */

      this.$refs.simpleRules.validate().then(success => {
        /* this.isNotSaved = true

        store
          .dispatch(this.action === 'add' ? 'app-hr/addForm' : 'app-hr/updateForm', this.form)
          .then(response => {
            // eslint-disable-next-line
            const jsonObjectLiterals = response.data.response.data

            // console.log(jsonObjectLiterals)

            this.id = jsonObjectLiterals.id
            this.form.id = this.id

            Swal.fire({
              icon: 'success',
              title: 'Saved!',
              text: 'Saved succesfully.',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(error => {
            console.error(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .finally(() => {
            self.isNotSaved = false
          }) */
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm' : 'app-hr/updateForm', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  // console.log(jsonObjectLiterals)

                  this.id = jsonObjectLiterals.id
                  this.form.id = this.id

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved succesfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })

          // eslint-disable-next-line
          // alert('form submitted!')
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    clearSignature() {
      this.$refs.signature.clearSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs.Signature.reset()
      }
    },
    undoSignature() {
      this.$refs.signature.undoSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs.Signature.reset()
      }
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''
    },
    previewFormFile(params) {
      Swal.fire({
        text: params.item,
        padding: '1em 3em',
        // eslint-disable-next-line
        imageUrl: this.$urlUpload + params.id + '/' + params.item,
        imageAlt: params.item,
        showConfirmButton: false,
        showCloseButton: true,
        returnFocus: false,
      })
    },
    deleteFormFile(params) {
      const self = this

      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        // eslint-disable-next-line
        text: 'Do you want to delete ' + Object.values(params)[1] + '?',
        confirmButtonText: 'Proceed',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark mr-1',
        },
        buttonsStyling: false,
        returnFocus: false,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-hr/deleteFormFile', params)
            .then(response => {
              // eslint-disable-next-line
              const jsonObjectLiterals = response.data.response.data

              // console.log(jsonObjectLiterals)

              // update object file
              const key = Object.keys(params)[1]
              self.form[key] = jsonObjectLiterals.data[key]
              self[`${key}File`] = jsonObjectLiterals.data[key]

              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                // eslint-disable-next-line
                text: 'Deleted succesfully.',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch(error => {
              console.error(error)

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1500,
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
